$theme: (
  "colors": (
    "black": #000000,
    "darker": #333333,
    "dark": #777777,
    "gray": #9f9f9f,
    "gray-blue": #548393,
    "green": #2ad62d,
    "green-matte": #a0de5b,
    "red": #ff4949,
    "red-matte": #dd4457,
    "orange": #fe8b25,
    "orange-matte": #f5a622,
    "yellow": #f7e71c,
    "yellow-matte": #faee58,
    "white": #ffffff,
    "white-dark": darken(#ffffff, 20%),
    "white-light": lighten(#ffffff, 40%),
    "magenta": #cc3c8e,
    "magenta-dark": darken(#cc3c8e, 20%),
    "magenta-light": lighten(#cc3c8e, 40%),
    // "blue": #48a1ce,
    "blue": #4a90e2,
    "blue-dark": darken(#48a1ce, 20%),
    "blue-light": #ecf5fa,
    "facebook": #3b5998,
    "linkedin": #0077b5
  ),
  "fonts": (
    "main": ("Roboto", "Helvetica", "Arial", sans-serif),
    "sizes":(
      "x-small": 10px,
      "smaller": 12px,
      "small": 14px,
      "medium": 16px,
      "large": 20px,
      "extra": 28px,
      "huge": 36px,
    )
  ),
  "screens": (
  	"mobile": 600px,
  	"tablet": 1000px,
    "max-width": 1200px,
    "laptop": 1300px,
  ),
  "shadows": (
    "white-box": 0 2px 10px 5px rgba(0, 0, 0, 0.05)
  )
);

// Helper for accessing theme
@function theme($keys...) {
  $map: $theme;
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}
