/* Roboto regular */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Roboto-Regular.ttf); /* For IE6-8 */
  src: local('Roboto'),
    local('Roboto-Regular'),
    url(/fonts/Roboto-Regular.woff2) format('woff2'),
    url(/fonts/Roboto-Regular.woff) format('woff'),
    url(/fonts/Roboto-Regular.ttf) format('truetype');
}

/* Roboto italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url(/fonts/Roboto-Italic.ttf); /* For IE6-8 */
  src: local('Roboto Italic'),
    local('Roboto-Italic'),
    url(/fonts/Roboto-Italic.woff2) format('woff2'),
    url(/fonts/Roboto-Italic.woff) format('woff'),
    url(/fonts/Roboto-Italic.ttf) format('truetype');
}

/* Roboto bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/Roboto-Bold.ttf); /* For IE6-8 */
  src: local('Roboto Bold'),
    local('Roboto-Bold'),
    url(/fonts/Roboto-Bold.woff2) format('woff2'),
    url(/fonts/Roboto-Bold.woff) format('woff'),
    url(/fonts/Roboto-Bold.ttf) format('truetype');
}

/* Roboto bold italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(/fonts/Roboto-BoldItalic.ttf); /* For IE6-8 */
  src: local('Roboto Bold Italic'),
    local('Roboto-BoldItalic'),
    url(/fonts/Roboto-BoldItalic.woff2) format('woff2'),
    url(/fonts/Roboto-BoldItalic.woff) format('woff'),
    url(/fonts/Roboto-BoldItalic.ttf) format('truetype');
}

/* Material icon font */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(/fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(/fonts/MaterialIcons-Regular.woff) format('woff'),
    url(/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'leapthru';
  src: url(/fonts/lt-icon/leapthru.eot);
  src: url(/fonts/lt-icon/leapthru.eot#iefix) format('embedded-opentype'),
      url(/fonts/lt-icon/leapthru.woff2) format('woff2'),
      url(/fonts/lt-icon/leapthru.woff) format('woff'),
      url(/fonts/lt-icon/leapthru.ttf) format('truetype'),
      url(/fonts/lt-icon/leapthru.svg#leapthru) format('svg');
  font-weight: normal;
  font-style: normal;
}
