@import 'style/base';
@import 'style/utilities/variables';

@import 'AppFrame/reset';
@import 'AppFrame/global';
@import 'AppFrame/fonts';

.poweredBy {
  @media (max-width: theme('screens', 'mobile')) {
    margin-left: 0;
  }
}

.loginContainer {
  display: flex;
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.poweredByLeapthru {
  position: fixed;
  bottom: 16px;
  height: 52px;
  width: 137px;
  right: 16px;
  background-repeat: no-repeat;

  @media (max-width: theme('screens', 'mobile')) {
    right: calc(50% - 68px);
  }
}
