@import 'style/utilities/variables';

:global {
  body {
    color: theme('colors', 'black');
    font-size: theme('fonts', 'sizes', 'medium');
    font-family: theme('fonts', 'main');
    line-height: 1.4;
    background-color: #f6f6f6;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  // Calendar
  .rbc-current-time-indicator {
    background-color: rgba(0, 0, 0, 0.87);
    height: 2px;
    width: 100%;
    &::before {
      content: " ";
      width: 0px;
      height: 0px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 12px solid rgba(0, 0, 0, 0.87);
      position: absolute;
      right: 0px;
      top: -6px;
    }
  }

  .rbc-event.rbc-selected {
    background-color: #fafafa;
  }

  .rbc-events-container > div {
    border-radius: 0;
    background-color: #FAFAFA;
    border: none !important;
    color: #555;
  }

  .rbc-label{
    color: rgba(0,0,0,0.24);
    font-size: 13px;
    font-weight: 300;
  }

  .rbc-event-label {
    display: none !important;
  }

  .rbc-time-view{
    border: none;
  }


  .rbc-time-content{
    border: none;
  }

  .rbc-time-content > * + * > * {
    border-left: none;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  .rbc-timeslot-group{
    border-bottom: none;
  }

  .toolbar-container {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .back-next-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    .btn {
      color: #4A90E2;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }
    .label-date{
      color: rgba(0,0,0,0.87);
      font-size: 14px;
      font-weight: bold;
    }
  }

}
